export default {
  data: () => ({
    tableChange: 0,
    tableData: {
      isLoading: false,
      columns: [],
      rows: [],
      totalRecords: 0,
      pageLength: 10,
      pageLengthOption: [10, 20, 40, 100]
    },
    tableParams: {
      columnFilters: {},
      sort: [],
      page: 1,
      perPage: 10
    },
    tableQueryString: {}
  }),
  methods: {
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps)
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage})
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage})
      this.loadItems()
    },
    onSortChange(params) {
      let sortLists = params.map(param => {
        let column = this.tableData.columns.find(c => c.field === param.field)
        return {
          field: column.sortField ? column.sortField : param.field,
          type: param.type
        }
      })

      this.updateParams({sort: sortLists})
      this.loadItems()
    },
    onColumnFilter(searchFields) {
      let setSearchField = {}
      for (const [key, value] of Object.entries(searchFields)) {
        if (value) {
          setSearchField[key] = value
        }
      }

      this.updateParams({columnFilters: setSearchField})
      this.loadItems()
    },
    loadItems() {
      this.getTableQueryString()
      this.tableChange++

    },
    getTableQueryString() {
      let {page, perPage, sort, columnFilters} = this.tableParams
      let sortList = []
      sort.filter(sortData => sortData.field).map(sortData => {
        sortList.push(`${sortData.field},${sortData.type}`)
      })
      let sortData = sortList.length > 0 ? sortList.join('|') : undefined

      this.tableQueryString = {
        page: page,
        perPage: perPage,
        sort: sortData,
        ...columnFilters
      }
    },
    switchLanguage(th, en = '') {
      if(this.$i18n.locale === 'th') {
        return th
      } else {
        return en ? en : th
      }
    },
    badgeVariant(value) {
      if (value === 20 || value === 21) {
        return 'primary'
      } else if (value === 1) {
        return 'secondary'
      } else if (value === 30) {
        return 'success'
      } else if (value === 50) {
        return 'danger'
      } else {
        return 'warning'
      }
    },
  }
}