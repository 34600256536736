import {
  CompanyService,
  PrefixService,
  UserGroupService,
  PositionService,
  AttachmentTypeService,
  ComplianceService,
  LocationService,
  DepartmentService,
  WorkTypeService,
  UserService,
  QuestionTypeService,
} from "@/services"

export default {
  methods: {
    listBloodType() {
      return [{
        bloodId: 1,
        bloodName: 'A'
      }, {
        bloodId: 2,
        bloodName: 'B'
      }, {
        bloodId: 3,
        bloodName: 'AB'
      }, {
        bloodId: 4,
        bloodName: 'O'
      },]
    },
    listWorkStatus() {
      let statusLists = [{
        statusId: 1,
        statusNameTH: 'ทั้งหมด',
        statusNameEN: 'All',
      }, {
        statusId: 99,
        statusNameTH: 'ที่เกี่ยวข้องกับตนเอง',
        statusNameEN: 'Related me',
      }, {
        statusId: 2,
        statusNameTH: 'รอแก้ไข',
        statusNameEN: 'Waiting for edit',
      }, {
        statusId: 3,
        statusNameTH: 'กำลังดำเนินการ',
        statusNameEN: 'In progress',
      }, {
        statusId: 4,
        statusNameTH: 'รออนุมัติ',
        statusNameEN: 'Waiting for approve',
      }, {
        statusId: 5,
        statusNameTH: 'จบการทำงาน',
        statusNameEN: 'Closed',
      }, {
        statusId: 6,
        statusNameTH: 'ไม่อนุมัติ',
        statusNameEN: 'Disapprove',
      }/*, {
        statusId: 7,
        statusNameTH: 'ยกเลิก',
        statusNameEN: 'Cancel',
      }*/]

      return statusLists.map(status => ({statusId: status.statusId, statusName: status['statusName' + this.$i18n.locale.toUpperCase()]}))
    },
    listTrainingTime() {
      let results = []
      for (let startTime = 8; startTime < 15; startTime++) {
        if (startTime === 11 || startTime === 12) {
          continue
        }

        let setEndTime = (startTime + 1).toString()
        results.push(`${startTime}:30 - ${setEndTime}:30`)

        /*for(let halfTime = 1; halfTime < 3; halfTime++) {
          let setHalfTimeStart, setHalfTimeEnd, setEndTime
          if (halfTime === 1) {
            setHalfTimeStart = '00'
            setHalfTimeEnd = '30'
            setEndTime = startTime
          } else {
            setHalfTimeStart = '30'
            setHalfTimeEnd = '00'
            setEndTime = (startTime + 1).toString()
          }

          results.push(`${startTime}:${setHalfTimeStart} - ${setEndTime}:${setHalfTimeEnd}`)
        }*/
      }

      return results
    },
    async listCompany() {
      const companyService = new CompanyService()
      let queryResult = await companyService.listData({page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listTrainingQuestionType() {
      const questionTypeService = new QuestionTypeService()
      let queryResult = await questionTypeService.listData({page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listPrefix() {
      const prefixService = new PrefixService()
      let queryResult = await prefixService.listData()
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listUserGroup() {
      const userGroupService = new UserGroupService()
      let queryResult = await userGroupService.listData()
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listPosition() {
      const positionService = new PositionService()
      let queryResult = await positionService.listData({page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listAttachmentType() {
      const attachmentTypeService = new AttachmentTypeService()
      let queryResult = await attachmentTypeService.listData({page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listCompliance() {
      const complianceService = new ComplianceService()
      let queryResult = await complianceService.listData({page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listLocation() {
      const locationService = new LocationService()
      let queryResult = await locationService.listData({page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listDepartment(queryString) {
      const departmentService = new DepartmentService()
      let queryResult = await departmentService.listData({...queryString, page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listWorkType() {
      const workTypeService = new WorkTypeService()
      let queryResult = await workTypeService.listData({page: 0})
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async getWorkType(pk) {
      const workTypeService = new WorkTypeService()
      let queryResult = await workTypeService.getData(pk)
      return queryResult.isSuccess ? queryResult.data.result : {}
    },
    async listUserWorkOwner() {
      const userService = new UserService()
      let queryResult = await userService.listWorkOwnerData()
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listUserContractorAdmin(companyId) {
      const userService = new UserService()
      let queryResult = await userService.listContractorAdminData(companyId)
      return queryResult.isSuccess ? queryResult.data.results : []
    },
    async listUserContractor(companyId) {
      const userService = new UserService()
      let queryResult = await userService.listContractorData(companyId)
      return queryResult.isSuccess ? queryResult.data.results : []
    },
  }
}